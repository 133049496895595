const env = process.env.NODE_ENV;

function onEnvVariables() {
    switch (env) {
        case 'local':
            return {
                LOGIN: `http://localhost:3000`,
                API_URL: `http://localhost:3000/proxy`,
                CAPTCHA_KEY: '6LeyB9saAAAAALscNBLXMzr5LnqDDEEsfhH2jkLD',
            };
        case 'development':
            return {
                LOGIN: `http://localhost:3000`,
                API_URL: `https://apidev.estori.co`,
                CAPTCHA_KEY: '6LeyB9saAAAAALscNBLXMzr5LnqDDEEsfhH2jkLD',
            };
        case 'production':
            return {
                LOGIN: `https://portal.estori.co/`,
                API_URL: `https://api.estori.co`,
                CAPTCHA_KEY: '6LeyB9saAAAAALscNBLXMzr5LnqDDEEsfhH2jkLD',
            };
    }
}
export const configEnv = onEnvVariables();
